import React from "react";
import { Layout } from "components";
import { ContactFormBlock, Hero, Loader, PostList } from "components/ACF";
import WordPressPreview from "components/WordPressPreview/WordPressPreview";
import { WpCategory, WpPage, WpPost } from "codegen";
import { graphql } from "gatsby";

interface PostsPageTemplateProps {
  location: Location & {
    state: {
      filteredPosts: WpPost[];
    };
  };
  data: {
    page: WpPage;
    posts: {
      nodes: WpPost[];
    };
    categories: {
      nodes: WpCategory[];
    };
  };
  pageContext: {
    previousPagePath: string;
    nextPagePath: string;
    postsPerPage: number;
    numberOfPages: number;
    currentPageNumber: number;
  };
}

const PostsPageTemplate = ({ pageContext, data, location }: PostsPageTemplateProps): JSX.Element => {
  const { page, posts, categories, wp } = data;
  const allPosts = posts.nodes.map((node) => node);
  const allCategories = categories.nodes.map((node) => node);

  const {
    webAppSettings: {
      webAppSettings: { globalContactBlock },
    },
  } = wp;

  const { numberOfPages, currentPageNumber } = pageContext;

  return (
    <Layout pageTitle={page.title} seo={page.seo}>
      <>
        {page?.acfLayout?.layout?.length > 0 ? (
          <Loader
            data={page?.acfLayout.layout.map(({ ...layout }) => ({
              ...layout,
            }))}
            location={location}
            browserLocation={location}
          />
        ) : (
          page?.content && (
            <div className="layout">
              <div dangerouslySetInnerHTML={{ __html: page?.content }} />
            </div>
          )
        )}
        <PostList
          postListItems={allPosts}
          postListCategories={allCategories}
          browserLocation={location}
          data={{
            currentPageNumber: currentPageNumber,
            numberOfPages: numberOfPages,
          }}
        />
        {globalContactBlock && globalContactBlock.row && <ContactFormBlock data={globalContactBlock} />}
      </>
    </Layout>
  );
};

export default WordPressPreview({ postType: "pages" })(PostsPageTemplate);

export const PostsPageQuery = graphql`
  query PostsPage($id: String!, $offset: Int!, $postsPerPage: Int!) {
    page: wpPage(id: { eq: $id }) {
      id
      title
      ...SEOPageQuery
      acfLayout {
        fieldGroupName
        layout {
          ...HeroQuery
          ...FlexibleBlockQuery
          ...PostListQuery
        }
      }
    }
    categories: allWpCategory(filter: { count: { gt: 0 } }) {
      nodes {
        id
        name
        slug
        uri
      }
    }
    posts: allWpPost(sort: { fields: [date], order: DESC }, limit: $postsPerPage, skip: $offset) {
      nodes {
        ...PostFields
      }
    }
    wp {
      webAppSettings {
        webAppSettings {
          globalContactBlock {
            row {
              contentType
              formId
              content
            }
          }
        }
      }
    }
  }
`;
